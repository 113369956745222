<template>
    <div class="pt-7 container-fluid">
        <p v-if="userType=='admin'">
            <b-button variant="success" @click="openAppointmentModal">
                <i class="fas fa-plus"></i> {{$t('appointment.create_new')}}
            </b-button>
        </p>
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('general.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="3">
                    <v-date-picker v-model="range" :locale="userLang||'tr'" mode="dates" :masks="masks" is-range @input="setDates">
                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <b-row>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                    <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                </b-col>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                    <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                </b-col>
                            </b-row>
                        </template>
                    </v-date-picker>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('availability.type')">
                        <el-select v-model="filter.type" filterable="" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option :label="$t('availability.online')" value="online"></el-option>
                            <el-option :label="$t('availability.normal')" value="normal"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.currency')">
                        <el-select v-model="filter.currency" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in currencyList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.appointment_status')">
                        <el-select v-model="filter.appointmentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting','canceled']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.payment_status')">
                        <el-select v-model="filter.paymentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting', 'canceled']" :key="key" :label="$t('general.payment_'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="appointments" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column :label="$t('general.appointment_id')" min-width="280">
                    <template v-slot="{row}">
                        <small>{{row.uuid}}</small>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.appointment_status')" min-width="150">
                    <template v-slot="{row}">
                        <b-badge :variant="row.appointment_status=='completed'?'success':(row.appointment_status=='waiting'?'primary':'danger')">
                            <template v-if="row.appointment_status=='completed'">{{$t('general.completed')}}</template>
                            <template v-if="row.appointment_status=='waiting'">{{$t('general.waiting')}}</template>
                            <template v-if="row.appointment_status=='canceled'">{{$t('general.canceled')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.doctor')" prop="doctor" min-width="260" v-if="userType!='doctor'">
                    <template v-slot="{row}">
                        <a href="#" @click.prevent='' :id="`popover-doctor-${row.uuid}`">
                            <i class="fas fa-info-circle text-primary"></i>
                        </a> {{row.doctor[0].title}} {{row.doctor[0].name}}
                        <b-popover :target="`popover-doctor-${row.uuid}`" triggers="hover focus">
                            <div class="text-center">
                                <span class="dblock" v-if="row.doctor[0].photo&&row.doctor[0].photo.trim()!=''">
                                    <b-avatar :src="cdnUrl+'/profile/'+row.doctor[0].photo"></b-avatar>
                                </span>
                                <small v-if="row.clinic[0]!=undefined">
                                    <i class="fas fa-building"></i> {{row.clinic[0].name}}</small><br>
                                <small>
                                    <i class="fas fa-stethoscope"></i> {{row.doctor[0].branch | showBranch}}</small><br>
                                <small>
                                    <i class="fas fa-map-marker-alt"></i> {{row.doctor[0].city | showCity}}</small><br>
                            </div>
                        </b-popover>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.patient')" prop="patient" min-width="200" v-if="userType!='patient'">
                    <template v-slot="{row}">
                        <a href="#" @click.prevent='' :id="`popover-patient-${row.uuid}`">
                            <i class="fas fa-info-circle text-primary"></i>
                        </a> {{row.patient[0].name}}
                        <b-popover :target="`popover-patient-${row.uuid}`" triggers="hover focus">
                            <div class="text-center">
                                <template v-if="row.patient[0].nontc">
                                    <small>
                                        {{$t('appointment.nontc')}}
                                    </small>
                                </template>
                                <small v-else>
                                    TC: {{row.patient[0].identity}}
                                </small>
                                <small v-if="row.note&&row.note.trim()!=''">
                                    <br>
                                    <b>{{$t('appointment.patient_note')}} :</b> {{row.note}}
                                </small>
                            </div>
                        </b-popover>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.type')" prop="availability.type" min-width="200">
                    <template v-slot="{row}">
                        <i :class="row.data.type=='online'?'fas fa-video':'fas fa-building'"></i>
                        {{row.data.type=='online'? $t('general.conversation_online'):$t('general.conversation_office')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.date')" prop="data.date" min-width="170">
                    <template v-slot="{row}">
                        {{row.data.date, 'DD.MM.YYYY ddd' | getIntDate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.time')" prop="data.time" min-width="120">
                    <template v-slot="{row}">
                        {{row.data.time | getIntTime}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.delay')" prop="data.min" min-width="120">
                    <template v-slot="{row}">
                        {{row.data.min}} {{$t('general.min')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.price')" prop="data.price" min-width="120">
                    <template v-slot="{row}">
                        {{row.data.price}} {{row.data.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.paid')" prop="data.price" min-width="120">
                    <template v-slot="{row}">
                        {{row.payment | getTotalPayment}} {{row.data.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_status')" min-width="170">
                    <template v-slot="{row}">
                        <span v-for="(item, index) in row.payment" :key="index+'-paymentStatus'" v-if="row.payment&&row.payment.length>0">
                            <b-badge :variant="(item.paymentStatus=='completed'?'success':(item.paymentStatus=='waiting'?'primary':'danger'))" @click.prevent='' :id="`popover-paymentStatus-${item.uuid}`">
                                {{$t('general.'+item.paymentStatus)}}
                            </b-badge>
                            <b-popover :target="`popover-paymentStatus-${item.uuid}`" triggers="hover focus" transfer v-if="!loading">
                                <div>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>{{$t('general.payment_status')}}</td>
                                                <td>
                                                    <b-badge :variant="item.paymentStatus=='completed'?'success':(item.paymentStatus=='waiting'?'primary':'danger')">
                                                        {{$t('general.'+item.paymentStatus)}}
                                                    </b-badge>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{$t('general.payment_type')}}</td>
                                                <td>
                                                    {{$t('general.'+item.paymentMethod)}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{$t('general.amount')}}</td>
                                                <td>
                                                    {{item.amount}} {{item.currency | getCurrency}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{$t('general.date')}}</td>
                                                <td>
                                                    {{item.createdAt | getDate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-popover>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="190">
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="160">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="openProcessModal(scope.$index, scope.row)" v-if="scope.row.appointment_status!='canceled' && (userType!='patient') || userType=='patient'&&(scope.row.appointment_status=='completed' || (scope.row.data.type=='online' && scope.row.meeting_url!=''))">{{$t('general.process')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="processModal.status" modal-classes="modal-danger" modal-content-classes="bg-gradient-primary" size="sm">
            <h6 slot="header" class="modal-title">{{$t('general.process')}}</h6>
            <div class="py-3 text-center" v-if="processModal.status">
                <template v-if="userType=='patient'">
                    <template v-if="processModal.appointment.appointment_status=='canceled'">
                        <b-alert variant="secondary" show class="m-0">{{$t('info.not_found_process')}}</b-alert>
                    </template>
                    <template v-else>
                        <base-button type="secondary" block size="sm" @click="doProcess('comment')" v-if="processModal.appointment.appointment_status=='completed'&& (userType=='patient'||userType=='doctor')">{{userType=='patient'?$t('general.write_comment'):$t('general.comment')}}</base-button>
                        <base-button type="secondary" block size="sm" @click="doProcess('online')" v-if="processModal.appointment.data.type=='online' && processModal.appointment.meeting_url!=''">{{$t('general.appointment_online')}}</base-button>
                    </template>
                </template>
                <template v-else-if="userType!='patient'">
                    <base-button type="secondary" block size="sm" @click="doProcess('edit')" class="mb-2" v-if="userType=='admin'">{{$t('general.edit')}}</base-button>
                    <div v-if="getPaymentStatus(processModal.appointment)" class="mb-2">
                        <base-button type="secondary" block size="sm" @click="doProcess('file')">{{$t('general.files')}}</base-button>
                        <base-button type="secondary" block size="sm" @click="doProcess('recipe')">{{$t('general.receipes')}}</base-button>
                        <base-button type="secondary" block size="sm" @click="doProcess('report')">{{$t('general.reports')}}</base-button>
                        <base-button type="secondary" block size="sm" @click="doProcess('note')">{{$t('general.notes')}}</base-button>
                        <template v-if="checkPastControl(processModal.appointment)">
                            <base-button type="secondary" block size="sm" @click="doProcess('online')" v-if="processModal.appointment.data.type=='online'&&processModal.appointment.appointment_status=='waiting'">{{$t('general.appointment_online')}}</base-button>
                        </template>
                        <template v-if="processModal.appointment.payment&&processModal.appointment.payment.length>0">
                            <base-button type="secondary" block size="sm" @click="doProcess('approveappointment')" v-if="userType=='admin'&&processModal.appointment.payment&&processModal.appointment.payment[0].paymentStatus=='completed'">{{$t('general.appointment_approve')}}</base-button>
                        </template>
                    </div>
                    <template v-else-if="userType=='doctor'">
                        <b-alert variant="secondary" show class="m-0">{{$t('general.payment_waiting')}}</b-alert>
                    </template>
                    <base-button type="secondary" block size="sm" @click="doProcess('payments')" v-if="userType=='admin'">{{$t('general.payments')}}</base-button>
                    <base-button type="secondary" block size="sm" @click="doProcess('cancel')" v-if="userType=='admin'">{{$t('general.appointment_cancel')}}</base-button>
                </template>
            </div>
            <template slot="footer">
                <base-button type="link" class="text-white ml-auto" @click="processModal.status = false">{{$t('general.close')}}</base-button>
            </template>
        </modal>

        <modal :show.sync="processModal.modal" :showClose="false" :backdropClose="false" @close="modalClose" size="lg">
            <template v-if="processModal.id=='file'">
                <h6 slot="header" class="modal-title">{{$t('general.file_list')}}</h6>
                <b-alert variant="info" :show="files.length===0">{{$t('info.no_files')}}</b-alert>
                <b-row>
                    <b-col md="3" class="mb-4" v-for="(item, index) in files" :key="index">
                        <div class="file-section">
                            <div class="file-name">
                                <a :title="item.originalName">{{item.originalName}}</a>
                            </div>
                            <div class="file-date">{{item.createdAt | getDate}}</div>
                            <div class="file-icon">
                                <i class="fas fa-file-image" v-if="item.type=='image'"></i>
                                <i class="fas fa-file-alt" v-else></i>
                            </div>
                            <div class="file-footer">
                                <base-button type="primary" size="sm" @click="openFile(item.uuid)">{{$t('general.open')}}</base-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <template v-if="processModal.id=='note'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.note_list')}}</h6>
                <base-button type="link" class="text-primary ml-auto" @click="showNote" v-if="userType=='doctor'&&!noteData.show">
                    <span v-if="noteData.data&&noteData.data.uuid!=undefined">
                        <i class="fas fa-edit"></i> {{$t('general.edit_note')}}
                    </span>
                    <span v-else>
                        <i class="fas fa-plus"></i> {{$t('general.add_note')}}
                    </span>
                </base-button>
                <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator" v-if="userType=='doctor'&&noteData.show">
                    <b-form role="form" @submit.prevent="handleSubmit(onUpdateNote)" v-loading="processModal.loading">
                        <base-input type="text" name="notetext" :nameAs="$t('general.appointment_note')" :label="$t('general.appointment_note')" :placeholder="$t('general.appointment_note')" v-model="noteData.text" :rules="{required:true}">
                        </base-input>
                        <base-button type="primary" native-type="submit" :disabled="processModal.loading || invalid" size="sm">{{$t('save')}}</base-button>
                        <base-button type="danger" size="sm" @click="noteData.show=false">{{$t('quest.cancel')}}</base-button>
                    </b-form>
                    <hr>
                </validation-observer>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in notes" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p>{{item.note}}</p>
                    <small>{{item.createdAt | getDate}}</small>
                    <base-button type="link" class="text-danger processModalDelete" @click="handleNoteDelete(item.uuid)" v-if="userType=='doctor'&&processModal.appointment.doctor[0].uuid==item.doctor.uuid&&processModal.appointment.uuid==item.appointment.uuid">
                        <i class="fas fa-trash"></i>
                    </base-button>
                </b-alert>
                <b-alert variant="info" :show="notes.length===0">{{$t('info.no_notes')}}</b-alert>
            </template>
            <template v-if="processModal.id=='recipe'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.recipe_list')}}</h6>
                <base-button type="link" class="text-primary ml-auto" @click="showRecipe" v-if="userType=='doctor'&&!recipeData.show">
                    <span v-if="recipeData.data&&recipeData.data.uuid!=undefined">
                        <i class="fas fa-edit"></i> {{$t('general.edit_recipe')}}
                    </span>
                    <span v-else>
                        <i class="fas fa-plus"></i> {{$t('general.add_recipe')}}
                    </span>
                </base-button>
                <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator" v-if="userType=='doctor'&&recipeData.show">
                    <b-form role="form" @submit.prevent="handleSubmit(onUpdateRecipe)" v-loading="processModal.loading">
                        <validation-provider :rules="'required'" :name="$t('general.appointment_recipe')" v-slot="{ errors, valid, invalid, validated }">
                            <b-form-group>
                                <label>{{$t('general.appointment_recipe')}}</label>
                                <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.appointment_recipe')" rows="5" no-resize required v-model="recipeData.text"></b-form-textarea>
                                <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </validation-provider>
                        <base-button type="primary" native-type="submit" :disabled="processModal.loading || invalid" size="sm">{{$t('save')}}</base-button>
                        <base-button type="danger" size="sm" @click="recipeData.show=false">{{$t('quest.cancel')}}</base-button>
                    </b-form>
                    <hr>
                </validation-observer>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in recipes" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p v-html="$options.filters.getHtmlText(item.text)"></p>
                    <small>{{item.createdAt | getDate}}</small>
                    <base-button type="link" class="text-danger processModalDelete" @click="handleRecipeDelete(item.uuid)" v-if="userType=='doctor'&&processModal.appointment.doctor[0].uuid==item.doctor.uuid&&processModal.appointment.uuid==item.appointment.uuid">
                        <i class="fas fa-trash"></i>
                    </base-button>
                </b-alert>
                <b-alert variant="info" :show="recipes.length===0">{{$t('info.no_recipes')}}</b-alert>
            </template>
            <template v-if="processModal.id=='report'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.report_list')}}</h6>
                <base-button type="link" class="text-primary ml-auto" @click="showReport" v-if="userType=='doctor'&&!reportData.show">
                    <span v-if="reportData.data&&reportData.data.uuid!=undefined">
                        <i class="fas fa-edit"></i> {{$t('general.edit_report')}}
                    </span>
                    <span v-else>
                        <i class="fas fa-plus"></i> {{$t('general.add_report')}}
                    </span>
                </base-button>
                <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator" v-if="userType=='doctor'&&reportData.show">
                    <b-form role="form" @submit.prevent="handleSubmit(onUpdateReport)" v-loading="processModal.loading">
                        <validation-provider :rules="'required'" :name="$t('general.appointment_report')" v-slot="{ errors, valid, invalid, validated }">
                            <b-form-group>
                                <label>{{$t('general.appointment_report')}}</label>
                                <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.appointment_report')" rows="5" no-resize required v-model="reportData.text"></b-form-textarea>
                                <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </validation-provider>
                        <base-button type="primary" native-type="submit" :disabled="processModal.loading || invalid" size="sm">{{$t('save')}}</base-button>
                        <base-button type="danger" size="sm" @click="reportData.show=false">{{$t('quest.cancel')}}</base-button>
                    </b-form>
                    <hr>
                </validation-observer>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in reports" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p v-html="$options.filters.getHtmlText(item.text)"></p>
                    <small>{{item.createdAt | getDate}}</small>
                    <base-button type="link" class="text-danger processModalDelete" @click="handleReportDelete(item.uuid)" v-if="userType=='doctor'&&processModal.appointment.doctor[0].uuid==item.doctor.uuid&&processModal.appointment.uuid==item.appointment.uuid">
                        <i class="fas fa-trash"></i>
                    </base-button>
                </b-alert>
                <b-alert variant="info" :show="reports.length===0">{{$t('info.no_reports')}}</b-alert>
            </template>
            <template v-if="processModal.id=='comment'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.appointment_comment')}}</h6>
                <base-button type="link" class="text-primary ml-auto" @click="showReport" v-if="(userType=='doctor'||userType=='patient')&&!commentData.show&&comments.length==0">
                    <span>
                        <i class="fas fa-edit"></i> {{$t('general.write_comment')}}
                    </span>
                </base-button>
                <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator" v-if="(userType=='doctor'||userType=='patient')&&commentData.show">
                    <b-form role="form" @submit.prevent="handleSubmit(onSubmitComment)" v-loading="processModal.loading">
                        <template v-if="userType=='patient'">
                            <validation-provider :rules="'required|min:10|max:750'" :name="$t('general.comment')" v-slot="{ errors, valid, invalid, validated }">
                                <b-form-group>
                                    <label>{{$t('general.comment')}}</label>
                                    <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.comment')" rows="5" no-resize required v-model="commentData.comment"></b-form-textarea>
                                    <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </validation-provider>
                            <strong>
                                <small>{{$t('general.rate_appointment')}} : </small>
                            </strong>
                            <b-input-group class="rateInput mb-3">
                                <b-form-rating v-model="commentData.rate" color="#ff8800" no-border></b-form-rating>
                            </b-input-group>
                            <div>
                                <base-button type="primary" native-type="submit" :disabled="processModal.loading || invalid || commentData.rate==0" size="sm">{{$t('save')}}</base-button>
                                <base-button type="danger" size="sm" @click="commentData.show=false">{{$t('quest.cancel')}}</base-button>
                            </div>
                        </template>
                        <template v-else>
                            <validation-provider :rules="'required|min:10|max:750'" :name="$t('general.comment')" v-slot="{ errors, valid, invalid, validated }">
                                <b-form-group>
                                    <label>{{$t('general.comment')}}</label>
                                    <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.comment')" rows="5" no-resize required v-model="commentData.doctor_comment"></b-form-textarea>
                                    <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </validation-provider>
                            <div>
                                <base-button type="primary" native-type="submit" :disabled="processModal.loading || invalid" size="sm">{{$t('save')}}</base-button>
                                <base-button type="danger" size="sm" @click="commentData.show=false">{{$t('quest.cancel')}}</base-button>
                            </div>
                        </template>
                    </b-form>
                </validation-observer>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in comments" :key="index">
                    <strong>{{item.patient.name}}</strong>
                    <div class="patient-rate">
                        <b-input-group class="rateInput mb-3">
                            <b-form-rating :value="item.rate" color="#ff8800" inline readonly no-border></b-form-rating>
                        </b-input-group>
                    </div>
                    <p class="mt-2">
                        <small v-html="$options.filters.getHtmlText(item.comment)"></small>
                    </p>
                    <small>{{item.createdAt | getDate}}</small>
                    <template v-if="item.doctor_comment&&item.doctor_comment!=''">
                        <hr class="mt-2">
                        <strong>{{$t('general.your_answer')}} : </strong>
                        <small v-html="$options.filters.getHtmlText(item.doctor_comment)"></small>
                    </template>
                </b-alert>
                <template v-if="userType=='doctor'&&commentData.comment!=''">
                    <base-button type="link" class="text-success" size="sm" v-if="commentData.data&&!commentData.data.publish" @click="publishComment(true)">{{$t('general.approve_publish')}}</base-button>
                    <base-button type="link" class="text-danger" size="sm" v-if="commentData.data&&commentData.data.publish" @click="publishComment(false)">{{$t('general.cancel_publish')}}</base-button>
                    <base-button type="link" class="text-default" size="sm" @click="commentData.show=true" v-if="commentData.doctor_comment==''&&!commentData.show">{{$t('appointment.send_comment')}}</base-button>
                </template>
            </template>
            <template v-if="processModal.id=='online'">
                <h6 class="modal-title">{{$t('general.appointment_online')}}</h6>
                <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator" v-if="userType=='admin'">
                    <b-form role="form" @submit.prevent="handleSubmit(onMeetingUrl)" v-loading="processModal.loading">
                        <hr class="my-4">
                        <base-input type="text" name="link" :nameAs="$t('general.appointment_online')" :label="$t('general.appointment_online')" :placeholder="$t('general.appointment_online')" v-model="processModal.data.meeting_url" :rules="{required:true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/}">
                        </base-input>
                        <template v-if="processModal.appointment.code && processModal.appointment.code!='' && processModal.appointment.doctor[0].vonage!==undefined && processModal.appointment.doctor[0].vonage && (processModal.data.meeting_url!=='https://meet.e-doktor.com/'+processModal.appointment.code && processModal.data.meeting_url!=='meet.e-doktor.com/'+processModal.appointment.code)">
                            <div>
                                <small>
                                    <strong>{{$t('general.meeting_code')}} : </strong> https://meet.e-doktor.com/{{processModal.appointment.code}}
                                </small>
                                <hr class="my-4">
                            </div>
                        </template>
                        <b-alert show variant="warning" v-if="invalid">
                            <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                        </b-alert>
                        <base-button type="primary" native-type="submit" :disabled="processModal.loading || invalid">{{$t('save')}}</base-button>
                    </b-form>
                </validation-observer>
                <div class="text-center" v-else>
                    <template v-if="!processModal.data.meeting_url || (processModal.data.meeting_url&&processModal.data.meeting_url.trim()=='')">
                        <b-alert variant="info" show>{{$t('info.meeting_url')}}</b-alert>
                    </template>
                    <template v-else>
                        <a :href="'https://'+processModal.data.meeting_url" target="_new">{{processModal.data.meeting_url}}</a>
                    </template>
                </div>
            </template>
            <template slot="footer">
                <base-button type="link" class="text-danger ml-auto" @click="modalClose">{{$t('general.close')}}</base-button>
            </template>
        </modal>

        <modal :show.sync="appointmentModal.status" :showClose="false" :backdropClose="false" @close="appointmentModalClose" size="lg">
            <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator" v-if="appointmentModal.status">
                <b-form role="form" @submit.prevent="handleSubmit(searchAvailability)" v-loading="processModal.loading">
                    <b-row>
                        <b-col md="4">
                            <!-- Hastalar -->
                            <validation-provider :rules="'required'" :name="$t('general.patient')" v-slot="{ errors }">
                                <base-input :label="$t('general.patient')">
                                    <el-select v-model="appointmentModal.formData.patient" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="selectPatient" :disabled="appointmentModal.formData.availability!=undefined">
                                        <el-option v-for="(item, key) in patientList" :key="key" :label="item.name+' - '+(item.phone?item.phone:'0000')" :value="item._id"></el-option>
                                    </el-select>
                                </base-input>
                                <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                    {{ errors[0] }}
                                </div>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <!-- Branş -->
                            <validation-provider :rules="'required'" :name="$t('general.branch')" v-slot="{ errors }">
                                <base-input :label="$t('general.branch')">
                                    <el-select v-model="appointmentModal.formData.branch" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getAppointmentQuery('branch')">
                                        <el-option v-for="(item, key) in branchList" :key="key" :label="(item.name[userLang]!==undefined?item.name[userLang]:item.name['tr'])" :value="item._id"></el-option>
                                    </el-select>
                                </base-input>
                                <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                    {{ errors[0] }}
                                </div>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <!-- Doktorlar -->
                            <base-input :label="$t('general.doctor')">
                                <el-select v-model="appointmentModal.formData.doctor" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getAppointmentQuery('doctor')" clearable>
                                    <el-option v-for="(item, key) in doctorList" :key="key" :label="item.title+item.name+' - '+item.phone" :value="item._id"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>

                        <b-col md="4">
                            <!-- Randevu Türü -->
                            <validation-provider :rules="'required'" :name="$t('availability.type')" v-slot="{ errors }">
                                <base-input :label="$t('availability.type')">
                                    <el-select v-model="appointmentModal.formData.type" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')">
                                        <el-option v-for="item in appointmentModal.type" :key="item" :label="$t('availability.'+item)" :value="item"></el-option>
                                    </el-select>
                                </base-input>
                                <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                    {{ errors[0] }}
                                </div>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <v-date-picker v-model="appointmentModal.formData.dates" :min-date='new Date()' :locale="userLang||'tr'" is-range>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <label class="form-control-label">{{$t('general.date_range')}}</label>
                                    <b-row>
                                        <b-col md="6">
                                            <input
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                                class="form-control"
                                            />
                                        </b-col>
                                        <b-col md="6">
                                            <input
                                                :value="inputValue.end"
                                                v-on="inputEvents.end"
                                                class="form-control"
                                            />
                                        </b-col>
                                    </b-row>
                                </template>
                            </v-date-picker>
                        </b-col>
                    </b-row>

                    <base-button type="primary" native-type="submit" :disabled="invalid">{{$t('general.search_availability')}}</base-button>

                    <template v-if="appointmentModal.hasPatientBalance&&appointmentModal.formData.balance">
                        <b-alert show variant="success" class="mt-3">
                            <strong>{{$t('appointment.patient_balance_info')}}</strong> : <span class="patientBalance" v-if="appointmentModal.formData.balance.try>0">{{appointmentModal.formData.balance.try}} {{'try' | getCurrency}}</span><span class="patientBalance" v-if="appointmentModal.formData.balance.usd>0">{{appointmentModal.formData.balance.usd}} {{'usd' | getCurrency}}</span><span class="patientBalance" v-if="appointmentModal.formData.balance.eur>0">{{appointmentModal.formData.balance.eur}} {{'eur' | getCurrency}}</span><span class="patientBalance" v-if="appointmentModal.formData.balance.gbp>0">{{appointmentModal.formData.balance.gbp}} {{'gbp' | getCurrency}}</span>
                        </b-alert>
                    </template>

                    <template v-if="appointmentModal.listStatus">
                        <hr>
                        <!-- Müsaitlik Takvimi -->
                        <h3>{{$t('pages.availability')}}</h3>
                        <div class="table-responsive onlineAvailabilityTable" v-if="appointmentModal.listStatus">
                            <template v-if="availability.length>0">
                                <div class="accordion" role="tablist">
                                    <template v-for="aItem in availability">
                                        <b-card no-body class="mb-1" :key="aItem.doctor._id" v-if="aItem.list.length>0">
                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                <b-button block v-b-toggle="aItem.doctor._id" variant="info">{{aItem.doctor.title}}{{aItem.doctor.name}}</b-button>
                                            </b-card-header>
                                            <b-collapse :id="aItem.doctor._id" visible accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <table class="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr v-for="(item, index) in aItem.list" :key="item.data">
                                                                <td>
                                                                    {{item.data, 'DD.MM.YYYY ddd' | getIntDate}}
                                                                    <span class="badge badge-primary dblock">{{item.list[0].min}} Dk</span>
                                                                </td>
                                                                <td v-for="litem in item.list" :key="item.data+'-'+litem.time" :class="'text-center table-'+(litem.status?(litem.available?(appointmentModal.formData.select.uuid == litem.uuid?'light':'success'):'warning'):'danger')">
                                                                    <span class="dblock"><b>{{litem.time | getIntTime}}</b></span>
                                                                    <span v-if="litem.status">{{litem.price}} {{litem.currency | getCurrency}}<br>
                                                                        <template v-if="appointmentModal.formData.select.uuid == litem.uuid">
                                                                            <base-button type="danger" size="sm" v-if="litem.available">{{$t('general.choosen')}}</base-button>
                                                                        </template>
                                                                        <template v-else>
                                                                            <base-button type="info" size="sm" v-if="litem.available" @click="selectAvailability(litem, aItem.doctor)">{{$t('general.choose')}}</base-button>
                                                                        </template>
                                                                    </span>
                                                                    <small v-else>{{$t('availability.close_date')}}</small>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </template>
                                </div>
                                <template v-if="appointmentModal.formData.select&&appointmentModal.formData.select.uuid!=''">
                                    <b-alert class="mt-3" show>
                                        {{$t('appointment.create_new_appointment_info', {patient:appointmentModal.formData.data.patient, date_time:getIntDate(appointmentModal.formData.select.date) + " " + getIntTime(appointmentModal.formData.select.time), price:appointmentModal.formData.select.price+" "+getCurrency(appointmentModal.formData.select.currency), branch:appointmentModal.formData.data.branch, expert:appointmentModal.formData.data.doctor, type:appointmentModal.formData.data.type, min:appointmentModal.formData.select.min+' '+$t('general.min')})}}
                                    </b-alert>
                                    <div v-if="appointmentModal.formData.availability==undefined">
                                        <b-form-group :label="$t('general.payment_type')" v-slot="{ ariaDescribedby }">
                                            <template v-if="appointmentModal.hasPatientBalance">
                                                <b-form-radio v-model="appointmentModal.formData.select.paymentType" :aria-describedby="ariaDescribedby" name="some-radios" value="balance">{{$t('general.collection')}}</b-form-radio>
                                            </template>
                                            <b-form-radio v-model="appointmentModal.formData.select.paymentType" :aria-describedby="ariaDescribedby" name="some-radios" value="banktransfer">{{$t('general.with_banktransfer')}}</b-form-radio>
                                        </b-form-group>
                                        <template v-if="appointmentModal.formData.select.paymentType=='banktransfer'">
                                            <b>{{$t('general.select_bank_info')}}</b><br>
                                            <b-form-group v-slot="{ ariaBankDescribedby }">
                                                <table class="table">
                                                    <tr v-for="item in banks" :key="item.id">
                                                        <td>
                                                            <b-form-radio v-model="appointmentModal.formData.select.bank" :aria-describedby="ariaBankDescribedby" name="some-bank-radios" :value="item.id">{{item.name}}</b-form-radio>
                                                        </td>
                                                        <td>
                                                            {{item.iban}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </b-form-group>
                                        </template>
                                    </div>
                                    <base-button type="success" @click="createAppointment">{{$t('appointment.create_appointment')}}</base-button>
                                </template>
                            </template>
                            <template v-else>
                                <b-alert show variant="info">
                                    {{$t('warning.not_found_availability')}}
                                </b-alert>
                            </template>
                        </div>
                    </template>
                </b-form>
                <hr>
            </validation-observer>
            <template slot="footer">
                <base-button type="danger" style="margin-top: -50px" @click="appointmentModalClose">{{$t('general.close')}}</base-button>
            </template>
        </modal>
        <modal :show.sync="paymentModal.status" @close="paymentModalClose" size="lg">
            <template slot="header">
                <h4 class="modal-title">
                    <b>{{$t('general.payments')}}</b>
                </h4>
            </template>
            <p v-if="!paymentModal.add&&paymentModal.data.appointment_status=='waiting'&&!getPaymentStatus(paymentModal.data)">
                <base-button type="success" size="sm" @click="paymentModal.add=true">{{$t('general.add')}}</base-button>
            </p>
            <div v-if="paymentModal.add">
                <p><base-button type="danger" size="sm" @click="paymentModal.add=false">{{$t('general.cancel')}}</base-button></p>
                <b-card>
                    <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(addPayment)" v-loading="processModal.loading">
                            <b-row>
                                <b-col md="4">
                                    <b-form-group :label="$t('general.payment_type')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio v-model="paymentModal.formData.paymentMethod" :aria-describedby="ariaDescribedby" name="some-radios" value="balance" v-if="paymentModal.hasPatientBalance">{{$t('general.collection')}}</b-form-radio>
                                        <b-form-radio v-model="paymentModal.formData.paymentMethod" :aria-describedby="ariaDescribedby" name="some-radios" value="banktransfer">{{$t('general.with_banktransfer')}}</b-form-radio>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" v-if="paymentModal.formData.paymentMethod=='banktransfer'">
                                    <base-input :label="$t('bank.name')" name="bank" :nameAs="$t('bank.name')" :rules="'required'">
                                        <el-select v-model="paymentModal.formData.bank" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required filterable="">
                                            <template v-for="item in banks">
                                                <el-option v-if="item.currency==paymentModal.data.availability[0].currency" :key="item._id" :label="item.name+' - '+item.currency.toUpperCase()" :value="item.id"></el-option>
                                            </template>
                                        </el-select>
                                    </base-input>
                                </b-col>
                                <b-col md="4" v-if="paymentModal.formData.paymentMethod">
                                    <base-input :label="$t('general.amount')" name="amount" :nameAs="$t('general.amount')" :rules="'required|min_value:0.1|max_value:'+(paymentModal.formData.paymentMethod=='balance'?paymentModal.data.balance[paymentModal.data.availability[0].currency]:paymentModal.data.availability[0].price)">
                                        <el-input-number v-model="paymentModal.formData.amount" :min="0" :max="paymentModal.formData.paymentMethod=='balance'?paymentModal.data.balance[paymentModal.data.availability[0].currency]:paymentModal.data.availability[0].price" :step="0.1" required></el-input-number>
                                        {{paymentModal.data.availability[0].currency | getCurrency}}
                                    </base-input>
                                </b-col>
                                <b-col md="4" v-if="paymentModal.formData.paymentMethod=='banktransfer'">
                                    <base-input :label="$t('general.payment_status')" name="bank" :nameAs="$t('general.payment_status')" :rules="'required'">
                                        <el-select v-model="paymentModal.formData.paymentStatus" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                            <el-option v-for="item in ['waiting', 'completed']" :key="item" :label="$t('general.'+item)" :value="item"></el-option>
                                        </el-select>
                                    </base-input>
                                </b-col>
                                <b-col cols="12" class="text-right">
                                    <base-button type="success" size="sm" native-type="submit" :disabled="paymentModal.loading || invalid">{{$t('general.save')}}</base-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <td><b>{{$t('general.payment_type')}}</b></td>
                            <td><b>{{$t('bank.name')}}</b></td>
                            <td><b>{{$t('general.amount')}}</b></td>
                            <td><b>{{$t('payment.date')}}</b></td>
                            <td><b>{{$t('general.payment_status')}}</b></td>
                            <td><b>{{$t('general.process')}}</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in paymentModal.data.payment" :key="item.uuid">
                            <td>
                                <span v-if="item.paymentMethod=='balance'">{{$t('general.balance')}}</span>
                                <span v-else-if="item.paymentMethod=='banktransfer'">{{$t('general.banktransfer')}}</span>
                                <span v-else-if="item.paymentMethod=='creditcard'">{{$t('general.creditcard')}}</span>
                            </td>
                            <td>
                                <span v-if="item.bank&&item.bank!=0">{{item.bank|getBankName}}</span>
                            </td>
                            <td>
                                {{item.amount}} {{item.currency | getCurrency}}
                            </td>
                            <td>
                                {{item.createdAt | getDate}}
                            </td>
                            <td>
                                <b-badge variant="warning" v-if="item.paymentStatus=='waiting'">{{$t('general.waiting')}}</b-badge>
                                <b-badge variant="success" v-if="item.paymentStatus=='completed'">{{$t('general.completed')}}</b-badge>
                                <b-badge variant="danger" v-if="item.paymentStatus=='canceled'">{{$t('general.canceled')}}</b-badge>
                            </td>
                            <td>
                                <template v-if="item.paymentStatus=='waiting'">
                                    <base-button type="success" size="sm" @click="bankTransferApproved(item.uuid)" v-if="item.paymentMethod=='banktransfer'">{{$t('appointment.approved')}}</base-button>
                                    <base-button type="danger" size="sm" @click="bankTransferDelete(item.uuid)" v-if="item.paymentMethod=='banktransfer'">{{$t('general.delete')}}</base-button>
                                </template>
                                <template v-else-if="item.paymentStatus=='completed'">
                                    <base-button type="danger" size="sm" @click="bankTransferCancel(item.uuid)" v-if="item.paymentMethod=='banktransfer'">{{$t('appointment.cancel')}}</base-button>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template slot="footer">
                <base-button type="link" class="text-danger ml-auto" @click="paymentModalClose">{{$t('general.close')}}</base-button>
            </template>
        </modal>
    </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import {
    APPOINTMENT_REQUEST,
    APPOINTMENT_UPDATE,
    PATIENT_FILES,
    PATIENT_NOTE_REQUEST,
    RECIPE_REQUEST,
    RECIPE_ADD,
    RECIPE_DELETE,
    PATIENT_NOTE_ADD,
    PATIENT_NOTE_DELETE,
    REPORT_REQUEST,
    REPORT_ADD,
    REPORT_DELETE,
    PAYMENT_UPDATE,
    COMMENT_REQUEST,
    COMMENT_UPDATE,
    COMMENT_ADD
} from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { getCookie } from '@/utils/cookie';
import Modal from '../../../components/Arg/Modal.vue';

const token = getCookie('user-token');
const session = getCookie('user-session');
let self;
let emptyData = {};

export default {
    components: { Modal },
    data() {
        return {
            banksList : [
                "halkbank",
                "vakifbank",
                "ziraat",
                "akbank",
                "anadolubank",
                "fibabanka",
                "sekerbank",
                "turkish_bank",
                "turk_ekonomi_bankasi",
                "turkiye_is_bankasi",
                "yapi_kredi_bankasi",
                "adabank",
                "citibank",
                "denizbank",
                "garanti",
                "hsbc",
                "ing_bank",
                "odeabank",
                "finansbank"
            ],
            commentData: {
                rate: 0,
                comment: '',
                doctor_comment: '',
                show: true,
                data: {}
            },
            noteData: {
                text: '',
                show: false,
                data: {}
            },
            recipeData: {
                text: '',
                show: false,
                data: {}
            },
            reportData: {
                text: '',
                show: false,
                data: {}
            },
            processModal: {
                appointment: {},
                loading: false,
                status: false,
                index: false,
                modal: false,
                data: {},
                id: '',
                type: '',
                payment: ''
            },
            appointmentModal: {
                status: false,
                listStatus: false,
                type: ['normal', 'online'],
                hasPatientBalance : false,
                formData: {
                    id: null,
                    data:{
                        patient:'',
                        balance: null,
                        doctor:'',
                        branch:'',
                        type:'',
                    },
                    branch:'',
                    doctor:'',
                    type:'',
                    date:'',
                    time:'',
                    patient:'',
                    start:'',
                    end:'',
                    dates:{
                        start: moment().toDate(),
                        end: moment().add(7, 'day').toDate(),
                    },
                    select:{
                        uuid: ''
                    }
                }
            },
            paymentModal: {
                status: false,
                loading: false,
                add: false,
                data: {},
                formData: {},
            },
            masks: {
                input: 'DD.MM.YYYY'
            },
            cdnUrl: process.env.VUE_APP_CDNURL,
            currencyList: ['try', 'usd', 'eur', 'gbp'],
            timer: null,
            loading: false,
            range: {
                start: moment()
                    .clone()
                    .startOf('month')
                    .toDate(),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(3, 'month')
                    .subtract(1, 'day')
                    .toDate()
            },
            status: {
                true: 'success',
                false: 'danger'
            },
            filter: {
                text: '',
                appointmentStatus: '',
                paymentStatus: '',
                currency: 'try',
                doctor: '',
                type: '',
                start: moment()
                    .subtract(2, 'month')
                    .clone()
                    .startOf('month')
                    .format('YYYYMMDD'),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .format('YYYYMMDD')
            },
            pagination: {
                limit: 10,
                page: 1
            }
        };
    },
    computed: {
        ...mapState(['userLang']),
        ...mapGetters({
            availability: 'getAvailabilities',
            appointments: 'getAppointments',
            total: 'getAppointmentTotal',
            pages: 'getAppointmentPages',
            userType: 'getUserType',
            branchList: 'getBranchList',
            doctorList: 'getDoctors',
            patientList: 'getPatients',
            cities: 'getCity',
            files: 'getFiles',
            notes: 'getNotes',
            recipes: 'getRecipes',
            reports: 'getReports',
            comments: 'getComments',
            banks: 'getBanks'
        })
    },
    methods: {
        checkPastControl(appointmentData){
            let date = appointmentData.availability[0].date;
            let time = appointmentData.availability[0].time;
            let dateTime = moment(`${date}${time}`, 'YYYYMMDDHHmm');
            let now = moment();
            return !dateTime.isBefore(now);
        },
        addPayment(){
            let paymentData = {
                "rules" : true,
                "paymentMethod" : self.paymentModal.formData.paymentMethod,
                "bank" : self.paymentModal.formData.paymentMethod == 'balance'?0:self.paymentModal.formData.bank,
                "availabilityId" : self.paymentModal.data.availability[0]._id,
                "uuid" : self.paymentModal.data.patient[0].uuid,
                "paymentStatus": self.paymentModal.formData.paymentMethod == 'balance' ? 'completed' : self.paymentModal.formData.paymentStatus,
                "payedAmount": self.paymentModal.formData.amount
            }

            self.$store
                .dispatch('ADD_PAYMENT', paymentData)
                .then(resp => {
                    self.paymentModal.loading = false;
                    if(resp){
                        self.paymentModal.formData = {};
                        self.paymentModal.add = false;

                        self.getData().then(() => {
                            self.paymentModal.data = _.find(self.appointments, item => {
                                return item.uuid == self.processModal.appointment.uuid;
                            });
                        });
                        self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                    }else{
                        self.$notify({ type: 'danger', message: self.$i18n.t('error.negative') });
                    }
                })
                .catch(err => {
                    self.paymentModal.loading = false;
                    handleError(self, err);
                });
        },
        getPaymentStatus(appointment){
            let price = appointment.availability[0].price;
            let paymentTotal = _.sumBy(_.filter(appointment.payment, {paymentStatus:'completed'}), 'amount');
            return paymentTotal >= price;
        },
        selectPatient(val) {
            let patient = _.find(this.patientList, { _id: val });

            this.appointmentModal.formData.balance = patient?.balance ?? null;

            let balance = this.appointmentModal.formData.balance?_.chain(patient.balance).pickBy(_.isNumber).values().sum().value():0;

            self.appointmentModal.hasPatientBalance = balance > 0;
        },
        getCurrency: value => {
            const currency = { try: '₺', usd: '$', eur: '€', gbp: '£' };

            return currency[value] !== undefined ? currency[value] : '';
        },
        getIntDate: (value, format = 'DD.MM.YYYY') => {
            return moment(value, 'YYYYMMDD')
                .locale(self.userLang)
                .format(format);
        },
        getIntTime: value => {
            return moment(value, 'HHmm').format('HH:mm');
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        showNote() {
            self.noteData.show = true;
        },
        showRecipe() {
            self.recipeData.show = true;
        },
        showReport() {
            self.reportData.show = true;
        },
        openFile(uuid) {
            var xhr = new XMLHttpRequest();

            xhr.open('GET', process.env.VUE_APP_CDNURL + '/' + uuid);
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.response) {
                    var data_url = URL.createObjectURL(xhr.response);
                    window.open(data_url);
                }
            };
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', token);
            xhr.setRequestHeader('Session', session);
            xhr.send();
        },
        paymentModalClose() {
            self.paymentModal.status = false;
            self.paymentModal.add = false;
            self.paymentModal.data = {};
            self.paymentModal.formData = {};
        },
        appointmentModalClose() {
            self.appointmentModal.status = false;
            self.appointmentModal.listStatus = false;
            self.appointmentModal.formData = {
                balance: null,
                id: null,
                data:{
                    patient:'',
                    balance: null,
                    doctor:'',
                    branch:'',
                    type:'',
                },
                branch:'',
                doctor:'',
                type:'',
                date:'',
                time:'',
                patient:'',
                start:'',
                end:'',
                dates:{
                    start: moment().toDate(),
                    end: moment().add(7, 'day').toDate(),
                },
                select:{
                    uuid: ''
                }
            };
            self.getData();
        },
        modalClose() {
            self.processModal = {
                appointment: {},
                loading: false,
                status: false,
                index: false,
                modal: false,
                data: {},
                id: '',
                type: '',
                payment: ''
            };

            self.noteData = {
                text: '',
                show: false,
                data: {}
            };

            self.recipeData = {
                text: '',
                show: false,
                data: {}
            };

            self.reportData = {
                text: '',
                show: false,
                data: {}
            };

            self.commentData = {
                rate: 0,
                comment: '',
                doctor_comment: '',
                show: true,
                data: {}
            };
        },
        doProcess(index) {
            self.processModal.status = false;
            switch (index) {
                case 'edit':
                    self.appointmentModal.formData.id = self.processModal.appointment.uuid;
                    self.appointmentModal.formData.availability = self.processModal.appointment.availability[0]._id;
                    self.appointmentModal.formData.patient = self.processModal.appointment.patient[0]._id;
                    self.appointmentModal.formData.doctor = self.processModal.appointment.doctor[0]._id;
                    self.appointmentModal.formData.branch = self.processModal.appointment.doctor[0].branch[0];
                    self.appointmentModal.formData.type = self.processModal.appointment.availability[0].type;
                    /*let date = moment(self.processModal.appointment.availability[0].date, 'YYYYMMDD').toDate();
                    self.appointmentModal.formData.dates = {
                        start: date,
                        end: date
                    };*/
                    self.getAppointmentQuery('branch', false);
                    self.openAppointmentModal();

                    self.searchAvailability();
                    break;
                case 'file':
                    self.getPatientFile();
                    break;
                case 'note':
                    self.getPatientNote().then(resp => {
                        self.noteData.data = _.find(self.notes, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.noteData.text = self.noteData.data ? self.noteData.data.note : '';
                    });
                    break;
                case 'recipe':
                    self.getPatientRecipe().then(resp => {
                        self.recipeData.data = _.find(self.recipes, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.recipeData.text = self.recipeData.data ? self.recipeData.data.text : '';
                    });
                    break;
                case 'report':
                    self.getPatientReport().then(resp => {
                        self.reportData.data = _.find(self.reports, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.reportData.text = self.reportData.data ? self.reportData.data.text : '';
                    });
                    break;
                case 'online':
                    let appointment = self.processModal.appointment;
                    if (appointment.meeting_url) {
                        self.processModal.data = _.clone({ meeting_url: appointment.meeting_url });
                    }
                    break;
                case 'comment':
                    self.getComment().then(resp => {
                        self.commentData.data = _.find(self.comments, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.commentData.comment = self.commentData.data ? self.commentData.data.comment : '';
                        self.commentData.rate = self.commentData.data ? self.commentData.data.rate : 0;
                        self.commentData.doctor_comment = self.commentData.data ? self.commentData.data.doctor_comment : '';
                        if (self.commentData.comment != '') {
                            self.commentData.show = false;
                        }
                    });
                    break;
                case 'cancel':
                    self.handleCancel();
                    break;
                case 'approvetransfer':
                    self.bankTransferApproved();
                    break;
                case 'approveappointment':
                    self.appointmentApproved();
                    break;
                case 'payments':
                    self.paymentModal.data = _.cloneDeep(self.processModal.appointment);
                    let currency = self.paymentModal.data.availability[0].currency;
                    self.paymentModal.hasPatientBalance = self.paymentModal.data.balance&&self.paymentModal.data.balance[currency]&&self.paymentModal.data.balance[currency]>0?true:false;
                    self.paymentModal.status = true;

                    if(self.banks.length == 0){
                        self.$store
                            .dispatch('BANK_REQUEST')
                            .catch(err => {
                                handleError(self, err);
                            })
                    }

                    break;
            }

            self.processModal.id = index;
            if (['approveappointment', 'cancel', 'approvetransfer', 'payments', 'edit'].indexOf(index) === -1) {
                self.processModal.modal = true;
            }
        },
        async openAppointmentModal() {
            if(self.patientList.length==0){
                await self.$store
                    .dispatch('PATIENT_REQUEST', { pagination: { limit: 100000 }, filter: {status:true}, fields: '_id name phone uuid', balance: true })
                    .catch(err => {
                        handleError(self, err);
                    })
            }

            if(self.appointmentModal.formData.patient){
                self.selectPatient(self.appointmentModal.formData.patient);
            }

            if(self.banks.length == 0){
                self.$store
                    .dispatch('BANK_REQUEST')
                    .catch(err => {
                        handleError(self, err);
                    })
            }
            
            self.appointmentModal.status = true;
        },
        openProcessModal(index, appointment) {
            self.processModal.appointment = _.cloneDeep(appointment);
            self.processModal.index = index;
            self.processModal.status = true;
        },
        setDates() {
            _.set(self.filter, 'start', moment(self.range.start).format('YYYYMMDD'));
            _.set(self.filter, 'end', moment(self.range.end).format('YYYYMMDD'));

            self.getData();
        },
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        addNew() {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(emptyData)
            };
        },
        handleEdit(row) {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(row)
            };
        },
        bankTransferApproved(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.transfer_approve_confirm'),
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { payment_status: 'completed' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData().then(() => {
                                self.paymentModal.data = _.find(self.appointments, item => {
                                    return item.uuid == self.processModal.appointment.uuid;
                                });
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        bankTransferDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.transfer_delete_confirm'),
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch('DELETE_PAYMENT', { uuid })
                        .then(resp => {
                            self.loading = false;
                            self.getData().then(() => {
                                self.paymentModal.data = _.find(self.appointments, item => {
                                    return item.uuid == self.processModal.appointment.uuid;
                                });
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        bankTransferCancel(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.transfer_cancel_confirm'),
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { payment_status: 'canceled' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData().then(() => {
                                self.paymentModal.data = _.find(self.appointments, item => {
                                    return item.uuid == self.processModal.appointment.uuid;
                                });
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        publishComment(status) {
            let uuid = self.processModal.appointment.uuid;
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.change_comment_publish'),
                icon: status ? 'success' : 'warning',
                showCancelButton: true,
                confirmButtonColor: status ? '#2dce89' : '#f5365c',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(COMMENT_UPDATE, { uuid, comment: { publish: status } })
                        .then(resp => {
                            self.loading = false;
                            self.getComment().then(resp => {
                                self.commentData.data = _.find(self.comments, item => {
                                    return item.appointment.uuid == self.processModal.appointment.uuid;
                                });
                                self.commentData.comment = self.commentData.data ? self.commentData.data.comment : '';
                                self.commentData.rate = self.commentData.data ? self.commentData.data.rate : 0;
                                self.commentData.doctor_comment = self.commentData.data ? self.commentData.data.doctor_comment : '';
                                if (self.commentData.comment != '') {
                                    self.commentData.show = false;
                                }
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        appointmentApproved() {
            let appointment = self.processModal.appointment;
            let id = appointment.uuid;
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.appointment_approve_confirm'),
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(APPOINTMENT_UPDATE, { id, appointment: { appointment_status: 'completed' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        handleCancel() {
            let appointment = self.processModal.appointment;
            let id = appointment.uuid;
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.cancel_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.cancel_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(APPOINTMENT_UPDATE, { id, appointment: { appointment_status: 'canceled' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        handleNoteDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.processModal.loading = true;
                    self.$store
                        .dispatch(PATIENT_NOTE_DELETE, uuid)
                        .then(resp => {
                            self.getPatientNote().then(resp => {
                                self.noteData.data = _.find(self.notes, item => {
                                    return item.appointment.uuid == self.processModal.appointment.uuid;
                                });
                                self.noteData.text = self.noteData.data ? self.noteData.data.note : '';
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.processModal.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        handleRecipeDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.processModal.loading = true;
                    self.$store
                        .dispatch(RECIPE_DELETE, uuid)
                        .then(resp => {
                            self.getPatientRecipe().then(resp => {
                                self.recipeData.data = _.find(self.recipes, item => {
                                    return item.appointment.uuid == self.processModal.appointment.uuid;
                                });
                                self.recipeData.text = self.recipeData.data ? self.recipeData.data.text : '';
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.processModal.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        handleReportDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.processModal.loading = true;
                    self.$store
                        .dispatch(REPORT_DELETE, uuid)
                        .then(resp => {
                            self.getPatientReport().then(resp => {
                                self.reportData.data = _.find(self.reports, item => {
                                    return item.appointment.uuid == self.processModal.appointment.uuid;
                                });
                                self.reportData.text = self.reportData.data ? self.reportData.data.text : '';
                            });
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.processModal.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        onSubmitComment() {
            let appointment = self.processModal.appointment.uuid;

            self.processModal.loading = true;
            self.$store
                .dispatch(COMMENT_ADD, { comment: self.commentData.doctor_comment != '' ? self.commentData.doctor_comment : self.commentData.comment, rate: self.commentData.rate, appointment })
                .then(resp => {
                    self.getComment().then(resp => {
                        self.commentData.data = _.find(self.comments, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.commentData.comment = self.commentData.data ? self.commentData.data.comment : '';
                        self.commentData.rate = self.commentData.data ? self.commentData.data.rate : 0;
                        self.commentData.doctor_comment = self.commentData.data ? self.commentData.data.doctor_comment : '';
                        if (self.commentData.comment != '') {
                            self.commentData.show = false;
                        }
                    });
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        getData() {
            return new Promise((resolve, reject) => {
                self.loading = true;
                self.$store
                    .dispatch(APPOINTMENT_REQUEST, { pagination: self.pagination, filter: self.filter })
                    .then((resp) => {
                        resolve(true);
                    })
                    .catch(err => {
                        handleError(self, err);
                    })
                    .finally(() => {
                        self.loading = false;
                    });
            });
        },
        onUpdateNote() {
            let appointment = self.processModal.appointment;
            let id = appointment.uuid;
            let doctor = appointment.doctor[0].uuid;
            let patient = appointment.patient[0].uuid;

            self.processModal.loading = true;
            self.$store
                .dispatch(PATIENT_NOTE_ADD, { note: self.noteData.text, doctor, patient, appointment: id })
                .then(resp => {
                    self.noteData = {
                        text: '',
                        show: false,
                        data: {}
                    };
                    self.getPatientNote().then(resp => {
                        self.noteData.data = _.find(self.notes, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.noteData.text = self.noteData.data ? self.noteData.data.note : '';
                    });
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdateRecipe() {
            let appointment = self.processModal.appointment;
            let id = appointment.uuid;
            let doctor = appointment.doctor[0].uuid;
            let patient = appointment.patient[0].uuid;

            self.processModal.loading = true;
            self.$store
                .dispatch(RECIPE_ADD, { text: self.recipeData.text, doctor, patient, appointment: id })
                .then(resp => {
                    self.recipeData = {
                        text: '',
                        show: false,
                        data: {}
                    };
                    self.getPatientRecipe().then(resp => {
                        self.recipeData.data = _.find(self.recipes, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.recipeData.text = self.recipeData.data ? self.recipeData.data.text : '';
                    });
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdateReport() {
            let appointment = self.processModal.appointment;
            let id = appointment.uuid;
            let doctor = appointment.doctor[0].uuid;
            let patient = appointment.patient[0].uuid;

            self.processModal.loading = true;
            self.$store
                .dispatch(REPORT_ADD, { text: self.reportData.text, doctor, patient, appointment: id })
                .then(resp => {
                    self.reportData = {
                        text: '',
                        show: false,
                        data: {}
                    };
                    self.getPatientReport().then(resp => {
                        self.reportData.data = _.find(self.reports, item => {
                            return item.appointment.uuid == self.processModal.appointment.uuid;
                        });
                        self.reportData.text = self.reportData.data ? self.reportData.data.text : '';
                    });
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        onMeetingUrl() {
            let appointment = self.processModal.appointment;
            let id = appointment.uuid;

            self.processModal.loading = true;
            self.$store
                .dispatch(APPOINTMENT_UPDATE, { id, appointment: { meeting_url: self.processModal.data.meeting_url } })
                .then(resp => {
                    self.processModal.loading = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        },
        getPatientFile() {
            self.processModal.loading = true;
            self.$store
                .dispatch(PATIENT_FILES, { patient: self.processModal.appointment.patient[0].uuid })
                .then(resp => {
                    self.processModal.loading = false;
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        getPatientNote() {
            return new Promise((resolve, reject) => {
                self.processModal.loading = true;
                self.$store
                    .dispatch(PATIENT_NOTE_REQUEST, { uuid: self.processModal.appointment.patient[0].uuid })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getPatientRecipe() {
            return new Promise((resolve, reject) => {
                self.processModal.loading = true;
                self.$store
                    .dispatch(RECIPE_REQUEST, {
                        filter: { patient: self.processModal.appointment.patient[0].uuid, doctor: self.processModal.appointment.doctor[0].uuid },
                        pagination: { limit: 10000 }
                    })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getPatientReport() {
            return new Promise((resolve, reject) => {
                self.processModal.loading = true;
                self.$store
                    .dispatch(REPORT_REQUEST, {
                        filter: { patient: self.processModal.appointment.patient[0].uuid, doctor: self.processModal.appointment.doctor[0].uuid },
                        pagination: { limit: 10000 }
                    })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getComment() {
            return new Promise((resolve, reject) => {
                self.processModal.loading = true;
                self.$store
                    .dispatch(COMMENT_REQUEST, self.processModal.appointment.uuid)
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getAppointmentQuery(section, setClear = true){
            let type = _.cloneDeep(self.appointmentModal.formData.type);
            if(section=='branch'){
                self.appointmentModal.listStatus = false;
                if(setClear){
                    self.setClearFormData(self.appointmentModal.formData, ['doctor', 'date', 'time']);
                }

                self.$store
                    .dispatch('DOCTOR_REQUEST', { pagination: { limit: 10000 }, filter: {branch: self.appointmentModal.formData.branch, status: true}, fields: 'uuid title name online office phone' })
                    .catch(err => {
                        handleError(self, err);
                    });
            }else if(section=='doctor'){
                self.appointmentModal.listStatus = false;
                self.appointmentModal.type = [];
                self.appointmentModal.formData.type = '';

                let doctor = _.find(self.doctorList, item => {
                    return item.uuid == self.appointmentModal.formData.doctor;
                });

                if(doctor){
                    if(doctor.office) self.appointmentModal.type.push('normal');
                    if(doctor.online)self.appointmentModal.type.push('online');
    
                    if(self.appointmentModal.type.length>0 && self.appointmentModal.type.indexOf(type)>-1){
                        self.appointmentModal.formData.type = type;
                    }else{
                        self.appointmentModal.formData.type = self.appointmentModal.type[0];
                    }
                }else{
                    self.appointmentModal.type = ['normal', 'online'];
                    self.appointmentModal.formData.type = 'normal';
                }

            }
        },
        setClearFormData(form, clearItem) {
            _.forEach(clearItem, item => {
                form[item] = '';
            });
        },
        searchAvailability(){
            self.appointmentModal.listStatus = false;

            let formData = {
                doctor: self.appointmentModal.formData.doctor?'d-'+self.appointmentModal.formData.doctor:'b-'+self.appointmentModal.formData.branch,
                type: self.appointmentModal.formData.type,
                start: moment(self.appointmentModal.formData.dates.start).format('YYYYMMDD'),
                end: moment(self.appointmentModal.formData.dates.end).format('YYYYMMDD')
            }

            self.$store
                .dispatch('AVAILABILITY_REQUEST', formData)
                .then(resp => {
                    self.appointmentModal.listStatus = resp;
                })
                .catch(err => {
                    handleError(self, err);
                });
        },
        selectAvailability(item, doctorData){
            let patient = _.find(self.patientList, item => {
                return item._id == self.appointmentModal.formData.patient;
            });

            let doctor = _.find(self.doctorList, ditem => {
                return ditem._id == doctorData._id;
            });

            let branch = _.find(self.branchList, item => {
                return item._id == self.appointmentModal.formData.branch;
            });

            let type = self.$i18n.t('availability.'+self.appointmentModal.formData.type);


            self.appointmentModal.formData.data = {
                patient: patient.name,
                doctor: `${doctor.title} ${doctor.name}`,
                branch: branch.name[self.userLang],
                type
            }

            self.appointmentModal.hasPatientBalance = patient.balance&&patient.balance[item.currency]&&patient.balance[item.currency]>0?true:false;

            self.appointmentModal.formData.select = {
                date: item.date,
                time: item.time,
                min: item.min,
                currency: item.currency,
                price: item.price,
                uuid: item.uuid,
                patient: patient.uuid,
                paymentType: self.appointmentModal.hasPatientBalance?'balance':'banktransfer',
                bank: 0
            }
        },
        createAppointment(){
            if(self.appointmentModal.formData.availability == undefined && self.appointmentModal.formData.select.paymentType=='banktransfer' && !self.appointmentModal.formData.select.bank){
                self.$notify({ type: 'danger', message: self.$i18n.t('error.select_bank_info') });
                return;
            }

            self.processModal.loading = true;
            let appointmentData = {
                "rules" : true,
                "paymentMethod" : self.appointmentModal.formData.select.paymentType,
                "bank" : self.appointmentModal.formData.select.bank,
                "availabilityId" : self.appointmentModal.formData.select.uuid,
                "uuid" : self.appointmentModal.formData.select.patient
            }

            if(self.appointmentModal.formData.availability != undefined){
                appointmentData = {
                    'oldAvailabilityId' : self.appointmentModal.formData.availability,
                    'availabilityId' : self.appointmentModal.formData.select.uuid,
                    'uuid' : self.appointmentModal.formData.select.patient
                }
            }
            
            self.$store
                .dispatch(self.appointmentModal.formData.availability != undefined?'UPDATE_APPOINTMENT':'CREATE_APPOINTMENT', appointmentData)
                .then(resp => {
                    self.processModal.loading = false;
                    if(resp){
                        self.appointmentModalClose();
                        self.$store
                            .dispatch('PATIENT_REQUEST', { pagination: { limit: 100000 }, filter: {status:true}, fields: '_id name phone uuid', balance: true })
                            .catch(err => {
                                handleError(self, err);
                            })
                        self.getData();
                        self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                    }else{
                        self.$notify({ type: 'danger', message: self.$i18n.t('error.negative') });
                    }
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        }
    },
    filters: {
        getBankName(value) {
            if(value=='balance') return self.$i18n.t('general.collection');
            
            let bank = _.map(
                _.pickBy(self.banks, item => {
                    return value == item.id;
                }),
                item => {
                    return item.name;
                }
            );
            return _.join(bank, ', ');
        },
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branches = _.map(
                _.pickBy(self.branchList, item => {
                    return item.status && _.includes(value, item._id);
                }),
                item => {
                    return item.name[lang];
                }
            );

            return _.join(branches, ', ');
        },
        showCity(value) {
            let city = _.map(
                _.pickBy(self.cities, item => {
                    return value == item._id;
                }),
                item => {
                    return item.name;
                }
            );
            return _.join(city, ', ');
        },
        getTotalPayment(value) {
            let total = 0;
            _.forEach(value, item => {
                if(item.paymentStatus=='completed'){
                    total += item.amount;
                }
            });
            return total;
        },
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
